import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Button, FormHelperText, Typography } from '@mui/material';
//
import { UploadAvatar, Upload, UploadBox } from '../upload';
import { useCallback, useState } from 'react';
import { fData } from '../../utils/formatNumber';
import { useLocales } from '../../locales';
// ----------------------------------------------------------------------
export function RHFUploadAvatar({ name, label, onClickButton, hasSubmitButton, disabled, ...other }) {
    const { control, setValue } = useFormContext();
    const [newFile, setNewFile] = useState();
    const { translate } = useLocales();
    const handleDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const newFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
        });
        if (file) {
            setValue(name, newFile, { shouldValidate: true });
            setNewFile(newFile);
        }
    }, [setValue]);
    const handleRemoveFile = () => {
        setValue(name, '', { shouldValidate: true });
        setNewFile('');
    };
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs("div", { children: [_jsx(Typography, { variant: "body2", sx: { color: 'text.secondary', mb: 2, px: 2, textAlign: 'center' }, children: label }), _jsx(UploadAvatar, { onDelete: handleRemoveFile, onDrop: handleDrop, accept: {
                        'image/*': [],
                    }, error: !!error, file: field.value, disabled: disabled, helperText: _jsxs(Typography, { variant: "caption", sx: {
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                        }, children: ["Autoris\u00E9 *.jpeg, *.jpg, *.png, *.gif ", _jsx("br", {}), " taille maximale de ", fData(3145728), _jsx("br", {}), field.value && onClickButton && (_jsx(Button, { variant: "contained", onClick: () => onClickButton(newFile), sx: { mt: 1, mr: 1 }, children: "enregistrer" })), field.value && (_jsx(Button, { disabled: disabled, variant: "outlined", onClick: handleRemoveFile, sx: { mt: 1 }, children: "supprimer" }))] }), ...other }), !!error && (_jsx(FormHelperText, { error: true, sx: { px: 2, textAlign: 'center' }, children: error.message }))] })) }));
}
// ----------------------------------------------------------------------
export function RHFUploadBox({ name, ...other }) {
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsx(UploadBox, { files: field.value, error: !!error, ...other })) }));
}
// ----------------------------------------------------------------------
export function RHFUpload({ name, multiple, helperText, files, label, ...other }) {
    const { control, setValue } = useFormContext();
    const handleDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const newFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
        });
        if (file) {
            setValue(name, newFile, { shouldValidate: true });
        }
    }, [setValue, name]);
    const handleRemoveFile = () => {
        setValue(name, '', { shouldValidate: true });
    };
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => multiple ? (_jsx(Upload, { multiple: true, accept: { 'image/*': [] }, files: field.value, onDrop: handleDrop, onDelete: handleRemoveFile, error: !!error, helperText: (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { px: 2 }, children: error ? `${error?.message}` : `${helperText}` })), ...other })) : (_jsx(Upload, { accept: { 'image/*': [] }, file: field.value, error: !!error, onDrop: handleDrop, onDelete: handleRemoveFile, helperText: (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { px: 2 }, children: error ? `${error?.message}` : `${helperText}` })), ...other })) }));
}
